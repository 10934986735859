<script setup lang="ts">
definePageMeta({
	layout: false,
	path: '/',
})

// We use lazy to avoid navigation blocking
const { data: categories } = await useFetch('/api/categories')

const title = 'En Honor a Su Verdad: Recursos para aprender la Biblia'
const description = 'Enseñanzas cristianas basadas en la Biblia que te ayudarán a construir una relación más profunda con Dios y vivir según Su palabra.'

useSeoMeta({
	titleTemplate: '%s',
	title,
	ogTitle: title,
	twitterTitle: title,
	description,
	ogDescription: description,
	twitterDescription: description,
})
</script>

<template>
	<div class="bg-[var(--ui-bg-muted)] dark:bg-[var(--ui-bg)] flex flex-col">
		<Navigation elevate-on-scroll="#scrolling-element" elevate-background elevate-shadow elevate-border />
		<div class="relative w-full max-w-7xl mx-auto py-12 sm:py-24 flex flex-col items-center gap-4 sm:gap-5 lg:gap-8 px-6">
			<div v-if="false" class="absolute bottom-0 left-0 -translate-x-1/3 translate-y-1/3">
				<UIcon name="ph:dots-nine-bold" class="size-[20rem] text-[var(--ui-bg-elevated)]" />
			</div>
			<div v-if="false" class="absolute bottom-0 right-0 translate-1/3">
				<UIcon name="ph:dots-nine-bold" class="size-[20rem] text-[var(--ui-bg-elevated)]" />
			</div>
			<div class="flex flex-col gap-4 text-center">
				<span class="text-[var(--ui-primary)] font-bold text-lg">En Honor a Su Verdad</span>
				<h1 class="relative max-w-4xl font-bold leading-tight font-serif text-3xl sm:text-4xl lg:text-6xl text-center text-pretty duration-200 text-[var(--ui-text)]">
					¿Quieres entender la Biblia, libre de tradiciones?
				</h1>
			</div>
			<p class="relative max-w-2xl text-center text-base sm:text-lg lg:text-xl leading-relaxed text-[var(--ui-text-muted)] duration-200 text-pretty">
				Aquí encontrarás estudios y lecciones bíblicas que te ayudarán a conocer mejor a Dios y a Su hijo Jesús para tener una vida espiritual plena.
			</p>
		</div>
		<div class="w-full max-w-7xl mx-auto pb-24 px-6">
			<!-- Categories -->
			<div class="flex flex-col gap-8 max-sm:-mx-2">
				<span class="text-center text-[var(--ui-text-muted)]">Elige una categoría de tu interés y comienza a aprender más sobre la Biblia: </span>
				<div class="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 sm:gap-12">
					<NuxtLink
						v-for="category in categories" 
						:to="`/categorias/${category.slug}`" class="bg-cover aspect-[3/4] bg-center shadow sm:shadow-lg rounded-[calc(var(--ui-radius)*2)] duration-500 overflow-hidden relative group cursor-pointer"
						:style="{
							backgroundImage: `url(https://ehsv.b-cdn.net/media/categories/${category.slug}.webp)`,
						}"
						>
						<div class="absolute w-full h-full hover:bg-white/10 duration-100 top-0 text-white z-10 text-center py-4 sm:py-8 px-4 sm:px-8 gap-2 flex flex-col">
							<h3 class="font-bold font-serif text-lg sm:text-2xl">{{ category.title }}</h3>
						</div>
					</NuxtLink>
				</div>
			</div>
		</div>
		<Footer />
	</div>
</template>
